import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { ProjectService } from "./services/project.service";
import { ProjectDetailComponent } from "./landing-page/projects/project-detail/project-detail.component";
import { AboutMeComponent } from "./landing-page/about-me/about-me.component";
import { LandingPageComponent } from "./landing-page/landing-page.component";
import { ProjectItemComponent } from "./landing-page/projects/project-list/project-item/project-item.component";
import { ProjectListComponent } from "./landing-page/projects/project-list/project-list.component";
import { ProjectsComponent } from "./landing-page/projects/projects.component";
import { SkillsComponent } from "./landing-page/skills/skills.component";


@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    LandingPageComponent,
    SkillsComponent,
    ProjectsComponent,
    ProjectDetailComponent,
    ProjectItemComponent,
    ProjectListComponent,
    AboutMeComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule.forRoot([]),
  ],
  providers: [ProjectService],
  bootstrap: [AppComponent]
})
export class AppModule { }
