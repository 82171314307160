import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, RouterLink } from '@angular/router';
import { ProjectService } from '../../../services/project.service';


@Component({
  selector: 'app-project-detail',
  templateUrl: './project-detail.component.html',
  styleUrls: ['./project-detail.component.scss']
})
export class ProjectDetailComponent implements OnInit {
  project: any;
  projectPath: any;
  isLoading = false;
  

  constructor(private projectService: ProjectService,
              private route: ActivatedRoute) { }


  ngOnInit(): void {
    this.route.paramMap.subscribe((paramMap: ParamMap) => {
      if(paramMap.has("projectPath")) {
        this.projectPath = paramMap.get("projectPath");
        this.project = this.projectService.getProjectByProjectPath(this.projectPath)
        console.log(this.project) 
      } else {
        this.projectPath = null;
      }
    })
  }
}
