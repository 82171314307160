import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { ProjectDetailComponent } from './landing-page/projects/project-detail/project-detail.component';


const routes: Routes = [
  {path: 'home', component: LandingPageComponent},
  {path: 'project/:projectPath', component: ProjectDetailComponent},
  {path: '**', redirectTo: '/home', pathMatch: 'full'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
