import { Injectable } from "@angular/core";
import { Project } from "../models/project.model";

@Injectable({
  providedIn: 'root'
})

export class ProjectService {
  private projects: Project[] = [
    {
      projectPath: 'pandemic-fall-of-rome',
      imagePath: "assets/images/PandemicFallOfRome.png", 
      shortDescription: "A really fun boardgame I re-created using Java and Firebase at the end of 2020. In 2021 i refactored the enitre codebase.", 

      longDescription: `At the end of 2020 due to the covid pandemic we got the school assignment to re-create
                        a boardgame of our choosing during a 20 week period. Me and my team desited to re-create Pandemic Fall Of Rome
                        because we all thought it would be a very fun game to work on and would be very challenging due to the
                        vast amount of different functionalities. During the project I leared a lot about 'object oriented programming'
                        and 'design patterns'. I later went on to refactor the entire project with the goal of learning more about 'clean code'
                        and removing 'code smells.', this was a very fun challenge considering i wrote some very dirty and terible code
                        during the creation of.`, 
      title: "Pandemic Fall Of Rome", 
      techStack: ["Java", "Firebase"]
    },
    {
      projectPath: 'vers-in-het-geheugen-ckm-1.0',
      imagePath: "assets/images/VersInHetGeheugen.png", 
      shortDescription: `When something happens to you, you are best able to retell your experience directly after it happend. After some time,
                         you will forget, displace or mixup details. Because of this victims of domesic violence and human trafficking are often
                         not believed in a court of law. For this reason during a school project me and some of my colleagues 
                         created a applicatie which ennabled victims of these kind of cases to document there experience.`, 

      longDescription: `When something happens to you, you are best able to retell your experience directly after it happend. After some time,
      you will forget, displace or mixup details. Because of this victims of domesic violence and human trafficking are often
      not believed in a court of law. For this reason during a school project me and some of my colleagues 
      created a applicatie which ennabled victims of domestic violence to document there experience so this documented proof
      could later be used in a court of law to get justice for these victims. During this project I learned to use Node.js
      with express.js, this also was my first time useing PostgreSQL, We used JavaFX for the front-end styling. At the end of this project
      our client was very happy with ther result which is alway nice to hear.`,

      title: "Vers in het geheugen CKM 1.0", 
      techStack: ["Java", "Node.js", "Express.js", "PostgreSQL"]
    },

    {
      projectPath: 'funko-store',
      imagePath: "assets/images/FunkoStore.png", 
      shortDescription: `A webshop I created using Angular, Node.js, Express.js, MongoDB and Mongoose. I created this webshop on my own
                        for one of my school subjects. My grade was a 9.`, 

      longDescription: `I peronoly really like Funko pops, they are the ultimate gift for one of you're nerdy friends when you dont know
      what to get them. For this reason i thought it would be fun to create a pseudo funkopop store selling my personal collection of pops.
      During this project I worked with Angular, Typescript, MongoDB and Mongoose for the first time. This project contained  a 
      customer portal and a admin portal. A customer was able to create an account, order items and keep track of their orders.
      A admin was able to add, edit and delte products. A admin was also able to upgrade accounts to an admin account, fill orders and 
      keep track of all orders.`, 

      title: "Funko Store", 
      techStack: ["Angular", "Typescript", "HTML", "CSS", "Node.js", "Express.js", "MongoDB", "Mongoose"]
    },

    {
      projectPath: 'social-media-monitoring',
      imagePath: "assets/images/SocialMediaMonitoring.png", 
      shortDescription: "This is a work in progress. The application is a tool for scraping, monitoring and marking social media posts. This application works for: Twitter, Snapchat, Instagram, Telegram and Facebook. ", 
      
      longDescription: `This tool can be used to monotor social media acitivity for Twitter, Snapchat, Instagram, Telegram and Facebook. On the dashboard you can enter
      one or more searchterms using and or functionality, the when the searchterm is entered the dashboard show the post corresponding to the searchterm, for example:
      You can enter the searchterm 'rellen' and 'maliveld', the application the starts searching for posts that contain the words 'rellen' and 'maliveld'. Within
      the dashboard you can mark posts and users so you can go back and look at the posts on a later moment.`,

      title: "Social Media Monitoring", 
      techStack: ["Angular", "Typescript", "HTML", "CSS", "Node.js", "Express.js", "MongoDB", "Mongoose"]
    },
    {
      projectPath: 'vers-in-het-geheugen-ckm-2.0',
      imagePath: "assets/images/VersInHetGeheugen.png", 
      shortDescription: "The Angular webapplication version of 'VersInHetGeheugen 1.0'", 
      longDescription: `When something happens to you, you are best able to retell your experience directly after it happend. After some time,
      you will forget, displace or mixup details. Because of this victims of domesic violence and humas trafficking are often
      not believed in a court of law. For this reason during a school project me and some of my colleagues 
      created a applicatie which ennabled victims of domestic violence to document there experience so this documented proof
      could later be used in a court of law to get justice for these victims. During this project I got to practice my 
      Angular and Node.js skills. The application is currently being used by CKM`,
      title: "Vers in het geheugen CKM 2.0", 
      techStack: ["Angular", "Typescript", "HTML", "CSS", "Node.js", "Express.js", "PostgreSQL"]
    },
    {
      projectPath: 'react-household-app',
      imagePath: "assets/images/Household-app.jpeg", 
      shortDescription: "A household managment app i created using React and Redux", 
      longDescription: `When i moved out to live on my own i wanted to create a application that ennabled me to create a shopping list,
      manage a list of todo's and save recipes that I really enjoyed the taste of. I also wanted to learn React and Redux.
      The only logical conclusion then ofcourse was, lates create a household app using React and Redux, so thats what is did. `, 
      title: "React household manager", 
      techStack: ["React", "Redux", "HTML", "CSS"]
    },
    {
      projectPath: 'robot-arm',
      imagePath: "assets/images/RobotArm.png", 
      shortDescription: `One of my hardware/software projects. I created a robotarm that used potentionmeters to recreate a specified
      route. I build this during summer brake after finishing my education in Mechatronics to get some more programming experience before starting at HS Leiden`, 
      longDescription: `One of my hardware/software projects. I created a robotarm that used potentionmeters to recreate a specified
      route. I build this during summer brake after finishing my education in Mechatronics to get some more programming experience before starting at HS Leiden`, 
      title: "Robotic Arm", 
      techStack: ["Arduino", "Fusion360", "3D printing"]
    },
    {
      projectPath: 'skittle-sorter',
      imagePath: "assets/images/skittles.jpg", 
      shortDescription: `One of my hardware/software projects. I created a skittle sorting machine that used a color sensor and some servo motors to sort the differt skittle colors.
                        I build this during my summer brake after finishing my education in Mechatronics to get some more programming experience before starting at HS Leiden.`, 
      longDescription: `One of my hardware/software projects. I created a skittle sorting machine that used a color sensor and some servo motors to sort the differt skittle colors.
      I build this during my summer brake after finishing my education in Mechatronics to get some more programming experience before starting at HS Leiden.`, 
      title: "Skittle sorter", 
      techStack: ["Arduino", "Fusion360", "3D printing"]
    },
    {
      projectPath: '2D-platformer',
      imagePath: "assets/images/Platformer.png", 
      shortDescription: "A small 2D platformer in made using Unity", 
      longDescription: `After finishing my eduction in Mechatronics I was looking for a fun softare project. I decided i would start working on a 2D platformer using Unity and Photoshop
                        because I had been intrested in buidling my own a game for a long time. I learned a lot during this projects. I also figured out building a pretty game on you're own
                        was a lot of work.`, 
      title: "2D Platformer", 
      techStack: ["Unity", "Photoshop"]
    },
    {
      projectPath: 'anycubic-i3-mega',
      imagePath: "assets/images/3D-printer.jpg", 
      shortDescription: "My 3D printer! A lot of fun to work with and upgrade", 
      longDescription: `I have made a lot of changes to this printer, some just visual, some functional. Lets talk about it!`, 
      title: "Anycubic I3 Mega", 
      techStack: ["Fusion360", "Autodesk Inventor", "3D printing"]
    },
    {
      projectPath: 'myFridge',
      imagePath: "assets/images/apie-scraper.png", 
      shortDescription: `Im trying to spend less money on groceries, to achieve this I am currenctly working on a applatiaction 
                        that lets you store recepis from ah.nl. If a product that you use in one of you're recipes has a discount 
                        you get a notifacition with the recipe and the price of the recipe.`, 
      longDescription: `Im trying to spend less money on groceries, to achieve this I am currenctly working on a applatiaction 
                        that lets you store recepis from ah.nl. If a product that you use in one of you're recipes has a discount 
                        you get a notifacition with the recipe and the price of the recipe. This project is still a work in progress!`, 
      title: "MyFridge", 
      techStack: ["Angular", "Typescript", "Javascript", "HTML", "CSS", "Node.js", "Express.js", "SQLite"]
    },
  ];

  getProjects() {
    return this.projects
  }

  getProjectByProjectPath(productPath: string) {
    for(let x = 0; x < this.projects.length; x++) {
      if(this.projects[x].projectPath == productPath) {
        return this.projects[x];
      }
    }
    return null
  }
}
