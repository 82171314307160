<div class="title">
  <h1>Projects</h1>
</div>
<div class="container">
  <div class="masonry-layout">
    <app-project-item
      *ngFor="let projectElement of projects; let i = index"
      [project]="projectElement"
      [index]="i">
    </app-project-item>
  </div>
</div>