<div class="skills">
    <div class="title">
        <h1>Skills</h1>
    </div>
    <div class="item">
        <div class="column">
            <ul>
                <h2>Languages</h2>
                <li>English</li>
                <li>Dutch</li>
                <li>Javascript</li>
                <li>Node.js</li>
                <li>Typescript</li>
                <li>Java</li>
                <li>Python</li>
                <li>HTML/CSS</li>
                <li>SQL</li>     
            </ul>
        </div>
        <div class="column">
            <ul>
                <h2>Frameworks</h2>
                <li>Bootstrap</li>
                <li>Express.js</li>
                <li>Mongoose</li>
                <li>Angular</li>
                <li>React</li>
                <li>Redux</li>
                <li>JavaFX</li>
            </ul>
        </div>
        <div class="column">
            <ul>
                <h2>Other skills</h2>
                <li>Git</li>
                <li>Photoshop</li>
                <li>MySQL, PostgreSQL, MongoDB</li>
                <li>Waterfall</li>
                <li>Scrum</li>
                <li>UML</li>
                <li>Autodesk Inventor</li>
                <li>Fusion360</li>
                <li>3D printing</li>
            </ul>
        </div>
    </div>
</div>
