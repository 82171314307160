
<div class="card">
  <img [src]="project.imagePath">
  <h2>{{project.title}}</h2>
  <p>{{project.shortDescription}}</p>
  <button 
    routerLinkActive="active" 
    [routerLink]="['../project/',project.projectPath]" 
    class="read-more-btn">Read more
  </button>
</div>
