<div class="title-container">
  <h1> Hi! My name is Nils van der Velden! </h1>
  <img src="../../../assets/images/me2.png" alt="test">
</div>
<div class="container">
  <div class="center-box">
    <div class="header">
      <h1>About me</h1>
    </div>
    <div class="content">
      <p>
          My name is Nils van der Velden, I am a Software Engineering student 
          at Leiden University of Applied Sciences with a previous education 
          in Mechatronics. I love to learn new things. Feel free to check out 
          my work bellow and get in touch if you have question about any project 
          I worked on or if you want to work with me.
      </p>
    </div>
    <div class="button-box">
      <a href="https://www.linkedin.com/in/nils-van-der-velden-10b115182/"><button type="button" href=""class="projects-btn">Linkedin</button></a> <br>
      <a href="https://github.com/nilsvandervelden"><button class="contaxct-btn">Github</button></a>
    </div>

  </div>
</div>
