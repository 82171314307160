<div class="title-container">
  <h1>{{project.title}}</h1>
</div>
<div class="container">
  <div class="center-box">
    <div class="header">
      <h1>About this project</h1>
    </div>
    <div class="content">
      <p>{{project.longDescription}}</p>
    </div>
    <div class="image-container">
      <img src={{project.imagePath}}>
    </div>
    <div class="header">
      <h1>Skills I used</h1>
    </div>
    <div class="techstack" *ngFor="let skill of project.techStack">
      <p>{{skill}}</p>
    </div>
  </div>
</div>